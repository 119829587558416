@use '../../../Styles/main.scss';

.header {
    background-color: #FFF;
    height: 100px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
    box-shadow: 0px 16px 16px 0px rgba(0,0,0,.09);
}

.nav {
    display: flex;
    justify-content: space-between;

    max-width: 1320px;
    margin: 0 auto;
}

.navMenu {
    display: flex;
    margin-right: 80px;
    list-style: none;
    padding-top: 5px;
}

.navMenu__logo {
    // font-size: 2rem;
    padding: 0 40px;
    margin-top: 9px;
    // line-height: 100px;
    height: fit-content;
}

.navToggle {
    color: #000;
    background-color: rgba(0,0,0,0);
    border: none;
    font-size: 1.6rem;
    padding: 24px 24px 20px 20px;

    line-height: 0px;

    display: none;

}

.navMenu__item {
    margin: 0;
    line-height: 90px;
    text-transform: uppercase;
    position: relative;
    &::before{
        content: "";
        display: block;
        width: 0%;
        height: 3px;
        background-color: #191919;
        margin-bottom: 10px ;
        margin: 0 auto;
        margin-bottom: 20px;
        position: absolute;
        left: 50%;
        bottom: 0;
        text-align: center;
        transition: all .5s ease;
    }

    &:hover::before {
        width: 80%;
        left: 10%;
    }
    // width: max-content;
}

.navMenu__link--logo {
    height: 80px;
    transition: all .3s ease-in;
    margin-top: 0px;
}

.navMenu__link--logo:hover {
    color: #2e353f;
}

.navMenu__link {
    // font-family: 'NeueHaasUnicaProMedium', sans-serif;
    font-family: "NeueHaasUnicaPro", sans-serif;
    font-size: .9rem;
    color: #494949;
    text-decoration: none;
    // background-color: rgba(0,0,0,0.25);
    padding: 0px 20px;
    // border-radius: .25rem;
    padding-top: 0px;
    text-align: center;

    .fa {
        padding-left: 10px;
    }   

    cursor: pointer;

}


.navMenu__link:hover {
    color: #000;
    // padding: 10px 10px;
    padding-top: 0px;
    transition: all .25s ease;
    cursor: pointer;
    color: #aeaeae;
}


.navMenu__link--active {
    color: #000;
    border-bottom: 2px solid #000;
}

// MEGAMENU 
/* Create three equal columns that floats next to each other */

/* Dropdown button */



.dropdown-content {
    display: none;
    position: fixed;
    background-color: #FFF;
    width: 100vw;
    left: 12px;
    box-shadow: 0px 16px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -5px;
    border-top: .25px solid rgba(0,0,0,0.09);
}

@keyframes megamenu__abre {
    from { opacity: 0; }
    to { opacity: 1; }
} 

.dropdown:hover .dropdown-content {
// .dropdown .dropdown-content {
    display: block; 
    animation-name: megamenu__abre;
    animation-duration: .8s;
    -webkit-animation-duration: .8s;
    margin-left: -.75rem !important;
}

.column {
    float: left;
    width: 16.4%;
    padding: 20px;
    background-color: #FFF;
    min-height: 250px;
    text-decoration: none;
}
  
.column__fix{
    width: 19.2%!important;
    padding-left: .75rem!important;
    padding-right: .75rem!important;
    // margin-left: -.5rem!important;
}
.column__img  {
    border: 8px solid #2e353f;
}

.column__tit {
    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-size: .9rem;
    line-height: 1rem;
    padding-top: 1.5rem;
    padding-left: 0rem;
    color: #5f5a5a;
    text-decoration: none;
    border-bottom: 1px solid #aeaeae;
    padding-bottom: 10px;
}
.column__tit--fixTop {
    margin-top: -1rem!important;
}
.column__tit--obras {
    font-size: .8rem!important;
}
.column__tit:hover {
    color: black;
    cursor: pointer;

}
.column__submenu {
    padding: 0px;
    margin: 0px;
    line-height: 1rem;
}
.column__submenu--item {
    display: flex;
    flex-direction: row;
    align-items: start;
}
.column__submenu--item p {
    padding: 0px;
    margin: 4px 0px;

    font-family: 'NeueHaasUnicaPro', sans-serif;
    font-size: .9rem;
    line-height: 1rem;
    color: #aeaeae;
    text-transform: none;
    font-weight: lighter;
}

.column__submenu--item p:hover {
    color: #000;
    cursor: pointer;
}

.column__submenu--item i {
    color: #aeaeae;
    margin-top: 5px;
    margin-right: 4px;
}

.dropdown::after .dropdown-content { 
    animation-duration: .25s; 
}

@media (max-width: 1024px) {
    
    .navMenu {
        margin-right: 20px;
    }

    .navMenu__item{
        margin: 0 10px;
    }

    .navMenu__link {
        padding: 6px 4px;
    }

    .navToggle {
        display: block;

    }

    .navToggle:focus:not(:focus-visible){
        outline: none;
    }

    .navMenu {
        margin-right: 20px;
        align-items: center;
        flex-direction: column;
        background-color: #f1f1f1;
        position: fixed;
        left: 0;
        top: 100px;
        width: 100vw;
        padding: 20px 0;

        height: calc(100% - 60px);
        overflow-y: auto;

        left: 100%;
        transition: left 0.4s ease-in;
    }
    .navMenu__item {
        line-height: 50px;
        border-bottom: 1px solid black;
    }


    .naveMenu--visible {
        left:0;
    }
}


@media (max-width: 768px) {

    .header {
        height: 80px;
    }
    
    .navMenu__logo {
        padding: 0 20px;
        margin-top: 0px;
    }

    .navMenu__link--logo {
        height: 60px;
        transition: all .3s ease-in;
        margin-top: 10px;
    }

    .navMenu {
        margin-right: 20px;
        align-items: center;
        flex-direction: column;
        background-color: #f1f1f1;
        position: fixed;
        left: 0;
        top: 80px;
        width: 100vw;
        padding: 20px 0;

        height: calc(100% - 60px);
        overflow-y: auto;

        left: 100%;
        transition: left 0.4s ease-in;
    }

    .navMenu__link {
        border-bottom: none;
    }
    
    .navMenu__link:hover {
        border-bottom: none;
    }

    .navToggle {
        display: block;

    }

    .navToggle:focus:not(:focus-visible){
        outline: none;
    }

    .navMenu__item {
        line-height: 50px;
        border-bottom: 1px solid black;

        &::before{
            content: "";
            display: none;
        }
    
        &:hover::before {
            display: none;
        }
    }


    .naveMenu--visible {
        left:0;
    }

    .dropdown-content {
        box-shadow: 0px 16px 16px 0px rgba(0,0,0,0);
    }
    .column {
        float: left;
        width: 100%;
        padding: 10px 30px 20px 0px;
        background-color: #f1f1f1;
        height: 50px;
        text-decoration: none;
    }
    .column__img  {
        display: none!important;
    }
    .column__tit {
       
        padding-top: .5rem;
        padding-left: .5rem;
        text-align: center;
        color: #aeaeae;
    }
    
}