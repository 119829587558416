.bannerContainer {

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 50px;
    flex-basis: auto;
    overflow: hidden;
    position: relative;
    margin: 20px 6px 10px 6px!important;
    background-color: grey;

}

.bannerContainer  .banner__over {

    width: 100%;
    height: 100%;
    position: absolute;
    top: 470px;
    left: 0px;
    background-color: rgba(0,0,0,.5) !important;
    padding-top: 220px;
    text-align: center;
    transition: all 450ms;
}

.bannerContainer:hover .banner__over {

    cursor: pointer;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    
    z-index: 40;

}

.banner__texto {

        font-family: 'Chakra Petch', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #FFF;
        width: 90%;
        margin: 0 auto;
        // padding-top: 50px;
        margin-bottom: 40%;
        text-align: center;
        z-index: 50;

}

.banner__background {

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 370px;
    width: 18%;
    box-shadow: 0px 0px 3px rgba(0,0,0,.5);
}


// SIZE XXL
@media (max-width: 1400px) {
    .banner__background {     
        min-width: 240px;
        min-height: 260px;
    } 
}
// SIZE XL
@media (max-width: 1200px) {
    .bannerContainer {
         padding-bottom: 50px;
    }
    .banner__background {     
        min-width: 205px;
        min-height: 260px;
    }
    .banner__texto {
        font-size: 1rem;
        width: 80%;
        padding-bottom: 20px;
    }
}
// SIZE L
@media (max-width: 1024px) {
    .bannerContainer {
        margin: 15px 8px 10px 8px !important;
    }
    .banner__background {     
        min-width: 135px;
        min-height: 230px;
    }
    .bannerContainer {
        padding-bottom: 25px;
    }
   
    .banner__texto {
            font-size: 1rem;
            line-height: 1.3rem;
            margin-bottom: 27%;
    }

    .bannerContainer:hover .banner__over {
        cursor: pointer;
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        padding-top: 96%;
        background-color: rgba(0,0,0,.5);
        z-index: 40;
    }

}
// SIZE MD
@media (max-width: 768px) { 
    .divisionContainer{
        width: 95%;
        margin: 30px 10px 30px 10px;
    }
    .bannerContainer {
        margin: 6px!important;
    }
    .banner__background {
        min-width: 100%;
        min-height: 150px;
    }
    .banner__texto {
        font-size: 1.3rem;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }
}
// SIZE XS
@media (max-width : 576) {}