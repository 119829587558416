.carousel__box, .carousel-item  {

    max-height: 800px;
}

.carousel-caption {
    padding-bottom: 1.5rem;
}
.carouselLogo {

    margin: 0 auto;
    margin-bottom: 0px;

    position: relative;
}

.carousel-indicators [data-bs-target]{
        
    height: 15px!important;
    width: 15px;
    border-radius: 50%;
    margin: 0px 10px 20px 10px;
    border: 2px solid #FFF;
    background-color: rgba(0,0,0,0);
    opacity: 1;

}

.carousel-indicators .active {
    background-color: #FFF!important;
}

.carousel__image {
    display: block;
    object-fit: contain;
}

.carousel__h1 {

    position: relative;

    font-family: 'Chakra Petch', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 4rem;
    color: #FFF;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 130px;
    padding: 20px;
    text-shadow: 3px 2px 4px rgba(0,0,0,.25);
    
}

.carouselLogo--entraDer {
    animation-name: entraTextoDer;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}
.carouselLogo--entraIzq {
    animation-name: entraTextoIzq;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}
.carousel__h1-entraIzq {
    animation-name: entraTextoIzq;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-direction: alternate;
}
.carousel__h1-entraDer {
    animation-name: entraTextoDer;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-direction: alternate;
}

@keyframes entraLogoDer {
    from{ opacity: 0; margin-right: -200p;}
    to{ opacity: 1; margin-right: 0px }
}
@keyframes entraLogoIzq {
    from{ opacity: 0; margin-right: 200p;}
    to{ opacity: 1; margin-right: 0px }
}

@keyframes entraTextoIzq {
    from{ opacity: 0; transform: translateX(-80px); }
    to{ opacity: 1; transform: translateX(0px);}
}

@keyframes entraTextoDer {
    from{ opacity: 0; transform: translateX(100px); }
    to{ opacity: 1; transform: translateX(0px);}
}


// SIZE XL
@media (max-width: 1200px) {
    .carouselLogo {
        height: 100%;
        width: 80%;
        margin: 0 auto;
        // margin-bottom: 0%;
    }   
    .carousel__h1{
        font-size: 3.6rem;
        width: 90%;
    }
}

// SIZE L
@media (max-width: 1024px) { 

    .carouselLogo {

        margin: 0 auto;
        margin-bottom: 0px;
    }

    .carousel__h1 {

        font-size: 3.4rem;
        line-height: 3.4rem;
        width: 90%; 
        margin-bottom: 70px;
        padding: 10px;
        
    }
    
    .carousel-indicators [data-bs-target]{
        
        height: 15px!important;
        width: 15px;
        border-radius: 50%;
        margin: 0px 10px 00px 10px;
        // display: none;

    }

}
// SIZE MD
@media (max-width: 768px) {


    .carouselLogo {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 0px;
    }

    .carousel__h1 {

        font-size: 2.8rem;
        line-height: 2.8rem;
        width: 90%; 
        margin-bottom: 70px;
        padding: 10px;
        
    }
    
    .carousel-indicators [data-bs-target]{
        
        height: 15px!important;
        width: 15px;
        border-radius: 50%;
        margin: 0px 10px 00px 10px;
    
    }
    // .carousel-control-next, .carousel-control-prev {
    //     display: none;
    // }
 }

// SIZE XS
@media (max-width : 576px) {

    .carousel__image {
        display: block;
        object-fit: cover;
        object-position: center;
        height: 400px;
        background-color: grey;
    }

    .carouselLogo {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 0px;
    }

    .carousel__h1 {
        font-size: 2.6rem;
        line-height: 2.6rem;
        width: 100%; 
        margin-bottom: 60px;
        padding: 10px 0px;
    }

    .carousel__h1--telecomunicaciones { 
        margin-bottom: 100px;
        font-size: 1.4rem;
    }

    .carousel-indicators {
        display: none;
    }
    
}