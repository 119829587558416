.formContainer {
    // background-image: url('../../../../public/images/home/back-form.jpg');
    // background-repeat: no-repeat;
    // background-size:cover ;
    max-height: 700px;
    padding: 80px !important;
    background: rgb(46,53,63);
    background: linear-gradient(0deg, rgba(46,53,63,1) 0%, rgba(34,34,34,1) 100%);

}

.formInputsBlock {
 
    display: flex;
    flex-direction: column;
}

.formInput__box, .formInput__box::placeholder {

    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: .25rem ;
    border: none;
    background-color: #f1f3f4;
    color: #222222  ;
    font-size: 1rem;
    font-weight: lighter;
    padding: .75rem .5rem;
    resize: none;
}

.formInput__textarea, .formInput__box::placeholder  {
    width: 100%;
    height: 92%;
    padding-top: 10px;
    margin-top: 2px;
}

.formInput__error {

    font-size: .8rem;
    line-height: .9rem;
    // color: rgb(179, 15, 15);
    color: rgba(256 ,256 ,256 ,.5);
    margin-bottom: 5px;
    margin-top: 4px;

}


.formInput__sent {
    font-size: 1rem;
    line-height: 1.4rem;
    color: rgb(20, 131, 63);
    margin-top: 0px;
    padding-bottom: 10px;
    
}

.contact__fileButton {
    border-radius: 30px;
    padding: 10px;
    background-color: #f1f3f4;
}

.buttonContainerUpload {
    background-color: rgba(34 ,34 ,34 ,0);
    font-family: "NeueHaasUnicaProLight", sans-serif;
    // text-transform: uppercase;
    font-size: .8rem;
    font-weight: lighter;
    color: #f1f3f4;
    // padding: 0px 30px 5px 30px;
    border-radius: .4rem;
    // border: 1px solid #f1f3f4;
    // width: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    transition: all .25s ease;
}


.buttonContainerUpload--label {
    padding-bottom: 16px;
    font-size: .9rem;
    margin-right: 20px;
}


// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
// @media (max-width: 1024px) {}
// // SIZE MD
@media (max-width: 768px) {
    .formContainer {
        padding: 80px 20px 60px 20px!important;
    }
}
// // SIZE XS
@media (max-width : 576px) {

    .formContainer {
        padding: 80px 20px 10px 20px!important;
        max-height: 800px;
    }
    
    .formInput__textarea, .formInput__box::placeholder  {
        height: 200px;
    }

    .buttonContainerUpload {
       
        font-size: .6rem;
        text-align: center;
        width: 93%;
    }
    
}

