.footer__back {
    bottom: 0;
    background-color: #f1f3f4;
}

.formItems {
    display: flex;
    flex-direction: row;
    justify-content:start;
    list-style-type: none;
    padding-left: 2rem!important
}

.formBloques {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 2rem 2.4rem ;
}

.formBloques__container {
    padding-right: 3rem;
}

.formBloques__tit {
    font-size: .8rem;
    font-weight: bolder;
}
.formBloques__txt {
    font-size: .8rem;
    line-height: 1.2rem;
    font-weight: normal;
}

.formItems__link {
    font-family: 'NeueHaasUnicaProBold';
    font-size: .8rem;
    padding: 4px 10px 4px 4px;
}
.formItems__link:hover {
    color: rgb(136, 136, 136);
    cursor: pointer;
}

.formItems__link--sep {
    padding-left: 10px;
    padding-right: 10px;
}

.link_txt {
    font-size: .8rem;
    line-height: 1.2rem;
    text-decoration: none;
    color: rgb(136, 136, 136);
}


.link_txt:hover {
    font-size: .8rem;
    line-height: 1.2rem;
    text-decoration: none;
    color: #000;
}


.footer__icon {

    min-width: 10px;
    margin: 0 auto;

}



// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
// @media (max-width: 1024px) {}
// // SIZE MD
// @media (max-width: 768px) {}
// // SIZE XS
@media (max-width : 576px) {
    .formItems {
        flex-direction: column;
        padding-top: 40px;
        padding-left: 0.9rem!important;
    }
    .formItems__link {
        font-size: 1rem;
        line-height: 1.8rem;

    }
    .formItems__link--sep {
        display: none;
    }
   
    .formBloques {
        flex-direction: column;
        padding: 1rem;
    }
    
    .formItems__image {
        margin-top: 50px;
    }
}

