.homeContainer {

    padding: 100px 0px 0px 0px;
    overflow: hidden;
    
}

@media (max-width: 768px) {

    .homeContainer {

        padding-top: 80px;
    
    }
    
}