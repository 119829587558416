// @use '../../Styles/main.scss';

.buttonContainer {
    background-color: rgba(34 ,34 ,34 ,0);
    font-family: 'NeueHaasUnicaPro';
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: lighter;
    color: #f1f3f4;
    padding: 10px 30px 12px 30px;
    border-radius: 3rem;
    border: 1px solid #f1f3f4;
    width: fit-content;
    margin: 0 auto;
    // margin-bottom: 20px;
    transition: all .25s ease;
}

.buttonContainer:hover {
    background-color: #f1f3f4;
    color: #191919;
    cursor: pointer;
}

.buttonContainer--dark {
    background-color: rgba(34 ,34 ,34 ,0);
    font-family: 'NeueHaasUnicaPro';
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: lighter;
    color: #191919;
    padding: 10px 30px 12px 30px;
    border: 1px solid #191919;
}

.buttonContainer--dark:hover {
    background-color: #191919;
    color: #f1f3f4;
    border: 1px solid #191919;
}



// SIZE MD
@media (max-width: 768px) { 
    .buttonContainer {
       
        margin-bottom: 10px;
        
    }
}