// @use '../../Styles/main.scss';

.division__container {
  position: relative;
  text-align: left;
  color: #f1f3f4;
  display: inline-flex;
}

.division__container--img {
  width: 100vw;
  // display: block;
  object-fit: cover;
  height: 500px;
  
}

.division__container--xl {
  height: 680px !important;
  display: inline-flex;
}

.division__container--imgLg {
  height: 570px;
}

.division__container--imgM {
  height: 440px;
}

.division__container--imgXl {
  height: 600px;
}

.division__container--textos {
  position: absolute;
  top: 120px;
  left: 120px;
  width: 60%;
}

.division__container--textFix {
  line-height: 1.4rem!important;
}

.division__container--textosTop {
  top: 70px !important;
}

.division__container--textosLg {
  width: 80%;
  top: 70px;
}

.division__container--header {
  font-family: "NeueHaasUnicaPro";
  color: #f1f3f4;
  font-size: 1rem;
  border-bottom: 1px solid white;
  width: fit-content;
  padding-bottom: 14px;
}

.division__container--title {
  font-family: "Chakra Petch";
  font-size: 3.5rem;
  line-height: 3rem;
  text-align: left;
  color: #f1f3f4;
  text-transform: uppercase;
}
.division__container--text {
  font-family: "NeueHaasUnicaProLight";
  font-size: 1.1rem;
  line-height: 2rem;
  color: #f1f3f4;
  font-weight: lighter;
}

.proyecto__containers {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  margin-bottom: 60px;
}

.proyecto__item--button {
  position: absolute;
  width: 100%;
  top: 45.4%;
  left: 0%;
}

.division__bajada--down {
  margin: 30px;
  color: #9e9e9e;
}

.division__bajada {
  font-family: "NeueHaasUnicaPro";
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bolder;
  padding: 100px 20% 50px 20%;
}

.division__bajada--tit {
  font-family: "NeueHaasUnicaProBold";
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bolder;
  padding: 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 17px;
  text-align: left;
}

// // SIZE XXL
@media (max-width: 1600px) {
  .division__container--textosLg {
    width: 80%;
    top: 80px;
  }
}
// // SIZE XL
@media (max-width: 1200px) {
  .division__container--textos {
    top: 70px;
    left: 50px;
    width: 90%;
  }

  .container__divisiones {
    // max-width: 100%!important;
    max-width: 100vw !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}
// // SIZE L
@media (max-width: 1024px) {
  .division__container--img {
    width: 100vw;
    height: 440px;
    object-fit: cover;
  }
  .division__container--imgXl {
    height: 660px;
  }
  .division__container--imgLg {
    height: 660px;
  }

  .division__container--textos {
    top: 50px;
    left: 50px;
    width: 90%;
  }
  .division__container--text {
    line-height: 2rem;
    font-size: 1rem;
  }
}
// // SIZE MD
@media (max-width: 768px) {
  .division__container--img {
    width: 100vw;
    height: 600px;
    object-fit: cover;
  }
  .division__container--imgXl {
    height: 780px;
  }

  .division__container--textos {
    position: absolute;
    top: 80px;
    left: 80px;
    width: 80%;
  }

  .container__divisiones {
    // max-width: 100%!important;
    max-width: 100vw !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}
// // SIZE XS
@media (max-width: 576px) {
  .division__container--img {
    width: 100vw;
    height: 750px;
    object-fit: cover;
  }

  .division__container--imgLg {
    height: 980px !important;
  }
  .division__container--imgXl {
    height: 820px;
  }
  .division__container--title {
    font-family: "Chakra Petch";
    font-size: 2.5rem;
    line-height: 2.5rem;
    text-align: left;
    color: #f1f3f4;
    text-transform: uppercase;
  }
  .division__container--text {
    font-family: "NeueHaasUnicaProLight";
    font-size: 1rem;
    line-height: 2rem;
    color: #f1f3f4;
    font-weight: lighter;
  }
  .division__container--textLg {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .division__container--textos {
    position: absolute;
    top: 100px;
    left: 40px;
    width: 80%;
  }
  .division__container--textosLg {
    width: 85%;
  }

  .division__bajada--tit {
    padding: 30px;
    padding-left: 15px;
    padding-top: 0px;
    margin-top: 50px;
    margin-left: 0px;
    text-align: left;
  }

  .proyecto__containers {
    padding-left: 15px;
    margin-bottom: 40px;
  }
}
