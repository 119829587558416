.contact__container {
    background: rgb(34,34,34);
    padding: 80px 0px 5px 80px;
    margin-bottom: 0px;
}


.contact__title {

    font-family: 'Chakra Petch';
    font-size: 3.5rem;
    text-align: left;
    color: #f1f3f4;
    text-transform: uppercase;
}

.contact__bajada {
    font-family: "NeueHaasUnicaProLight", sans-serif;
    font-size: 1.4rem;
    color: #f1f3f4;
    width:fit-content;
}
.contact__text {
    font-family: "NeueHaasUnicaPro", sans-serif;
    font-size: 1rem;
    color: #f1f3f4;
    width:fit-content;
}


// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
@media (max-width: 1024px) {
    .contact__container {
        padding-left: 10%;
    }
    
}
// // SIZE MD
// @media (max-width: 768px) {}
// // SIZE XS
@media (max-width : 576px) {
    .contact__container {
        padding-left: 34px;
        padding-top: 80px;
    }
    
}

