* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

$main-color: #2e353f;
$text-color: #030303;
$text-color-ligth: #aeaeae;

:hover {
  // cursor: pointer;
}

html {
  height: 100%;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "NeueHaasUnicaProLight";
  src: local("NeueHaasUnicaPro"),
    url(../../public/Fonts/NeueHaasUnicaPro/NeueHaasUnicaPro-Light.ttf)
      format("truetype");
}

@font-face {
  font-family: "NeueHaasUnicaPro";
  src: local("NeueHaasUnicaPro"),
    url(../../public/Fonts/NeueHaasUnicaPro/NeueHaasUnicaPro-Regular.ttf)
      format("truetype");
}
@font-face {
  font-family: "NeueHaasUnicaProMedium";
  src: local("NeueHaasUnicaPro"),
    url(../../public/Fonts/NeueHaasUnicaPro/NeueHaasUnicaPro-Medium.ttf)
      format("truetype");
}
@font-face {
  font-family: "NeueHaasUnicaProBold";
  src: local("NeueHaasUnicaProBold"),
    url(../../public/Fonts/NeueHaasUnicaPro/NeueHaasUnicaPro-Bold.ttf)
      format("truetype");
}
@font-face {
  font-family: "NeueHaasUnicaProMedium";
  src: local("NeueHaasUnicaProBold"),
    url(../../public/Fonts/NeueHaasUnicaPro/NeueHaasUnicaPro-Medium.ttf)
      format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  min-height: 100%;
  background-color: #f1f3f4;
}

.box {
  border: 1px solid orange;
}

.display-block {
  padding-top: 100px !important;
}

/* animaciones  */

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.modal {
  z-index: 1600;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-footer {
  align-items: center;
  justify-content: center;
}

.modal__size {
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}

// STYLES GENERICOS
.display-block {
  margin-top: 0px;
  background-color: #f1f3f4;
}

.containerBlock {
  margin-top: 100px;
}

a {
  color: #191919;
  text-decoration: none;
}

a:hover {
  color: #4f5866;
}

.section__container {
  position: relative;
  text-align: left;
  color: #f1f3f4;
}

.section__detail--header {
  object-fit: cover;
  height: 100%;
}

.section__container--textos {
  position: absolute;
  top: 120px;
  left: 120px;
  width: 60%;
}

.section__container--header {
  font-family: "NeueHaasUnicaPro";
  color: #f1f3f4;
  font-size: 1rem;
  border-bottom: 1px solid white;
  width: fit-content;
  padding-bottom: 14px;
}

.section__box {
  padding: 60px;
}

.section__tittle {
  font-family: "NeueHaasUnicaProBold";
  font-size: 2.5rem;
  line-height: 2.5rem;
  text-align: left;
  color: #121212;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
}

.section__text {
  font-family: "NeueHaasUnicaProLight";
  font-size: 1rem;
  line-height: 1.7rem;
  color: #121212;
  font-weight: lighter;
  margin-top: 20px;
}

.section__container--title {
  font-family: "Chakra Petch";
  font-size: 3.5rem;
  text-align: left;
  color: #f1f3f4;
  text-transform: uppercase;
}

.section__container--img {
  width: 100vw;
  height: 300px;
  object-fit: cover;
  object-position: top;
}

// ++++++++ OBRAS +++++++++ //
.obrasItem__detail {
  padding-top: 60px;
}

.obras__box {
  margin-top: 80px;
  margin-bottom: 40px;
}

.obras__box--short {
  margin-top: 40px;
  margin-bottom: 20px;
}

.obras__card {
  padding: 10px 20px;
}

.obras__cont {
  // background-color: #FFF;
  // border-radius: 1rem;
  // border-top-left-radius: 4rem;
  // padding: 1rem 2rem 1rem 0rem;
  // text-align: center;
  // min-height: 180px;
  // height: 100%;
  // width: 100%;
  background-color: #fff;
  border-radius: 1rem;
  border-top-left-radius: 4rem;
  padding: 0rem;
  text-align: center;
  min-height: 180px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.obras__cont-inline {
  display: block;
  padding-top: 2rem;
}
.obras__cont-inlineXL {
  display: block;
  padding-top: 1rem;
}
.obras__cont--num {
  font-family: "Chakra Petch";
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 2.5rem;
}

.obras__cont--numXL {
  font-family: "Chakra Petch";
  font-weight: 700;
  // font-size: 2.5rem;
  // line-height: 2rem;
  font-size: 4rem;
  line-height: 3rem;
}

.obras__cont--numShort {
  font-size: 3.8rem;
  line-height: 2.5rem;
}

.obras__cont--small {
  font-family: "Chakra Petch"!important;;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
}
.obras__cont--smallXL {
  font-family: "Chakra Petch"!important;
  font-weight: 700!important;
  font-size: 1.4rem!important;
  line-height: 1.4rem!important;
  text-transform: uppercase!important;
}

.obras__cont--item {
  font-family: "Chakra Petch";
  font-weight: 500;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1rem;
}
.obras__cont--det {
  text-transform: uppercase;
  font-family: "NeueHaasUnicaProLight";
  font-size: 1rem;
  line-height: 1rem;
}

.otrasObras__container {
  background-color: #fff;
  margin-top: 80px;
  padding-top: 10px;
}
// ++++++++ FIN OBRAS +++++++++ //

.carousel__box--image {
  object-fit: cover;
  object-position: center;
  margin: 0 auto !important;
  height: 100%;
}

// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
@media (max-width: 1200px) {
  .obras__box {
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .obras__cont {
    padding: 0.5rem;
    min-height: 170px;
  }
  .obras__card {
    padding: 10px;
  }
  .obras__cont--num {
    font-size: 3.4rem;
  }
}
// // SIZE L
@media (max-width: 1024px) {
  .obras__cont {
    padding-top: 0.8rem;
    min-height: 170px;
  }
  .obras__box {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
// // SIZE MD
@media (max-width: 768px) {
  .display-block {
    padding-top: 80px !important;
  }
}
// // SIZE XS
// @media (max-width : 576px) {}

// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
// @media (max-width: 1024px) {}
// // SIZE MD
// @media (max-width: 768px) {}
// // SIZE XS
// @media (max-width : 576px) {}

// div,
// span,
// h1,
// h2,
// h3,
// h4,
// h5 {
//   border: 0.25px solid red !important;
// }
