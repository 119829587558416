// @use '../../Styles/main.scss';

.containerBlock {
    margin-top: 100px;
}

a {
    color: #191919;
    text-decoration: none;
}

a:hover {
    color: #4f5866;
}

.divisionItem__container {
    position: relative;
    text-align: left;
    color: #f1f3f4;
}

.divisionItem__detail--header {
    height: 100%;
    object-fit: scale-down;
    object-position: top;
}


.divisionItem__container--textos {
    position: absolute;
    top: 120px;
    left: 120px;
    width: 60%;
}

.divisionItem__container--textos-2-lineas { top: 100px; left: 50px; }

.divisionItem__container--textos-obras { top: 80px; left: 120px; }

.divisionItem__container--header {
    font-family: 'NeueHaasUnicaPro';
    color: #f1f3f4;
    font-size: 1rem;
    border-bottom: 1px solid white;
    width:fit-content;
    padding-bottom: 14px;


}

.divisionItem__container--title {

    font-family: 'Chakra Petch';
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-align: left;
    color: #f1f3f4;
    text-transform: uppercase;

}


.divisionItem__container--img {
    width: 100vw;
    height: 300px;
    object-fit: cover;
    object-position: top;
}

.divisionItem__detail {
    // background-color: #FFF;
    padding: 60px;
}

.divisionItem__detail--block{
    padding: 10px 60px;
}

.divisionItem__detail--blockObras {
    padding: 10px 10px;
}

.divisionItem__detail--title {
    font-family: 'NeueHaasUnicaProBold';
    font-size: 2rem;
    line-height: 2.2rem;
    // font-weight: bolder;
    margin-bottom: 30px;
    text-align: left;
    color: #191919;
}

.divisionItem__detail--subtitle {
    font-family: 'NeueHaasUnicaProMedium', sans-serif;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: bolder;
    margin-bottom: 30px;
    text-align: left;
    color: #191919;
}

.divisionItem__detail--text {
    font-family: 'NeueHaasUnicaPro';
    font-size: 1rem;
    line-height: 1.6rem;
    color: #191919;
    margin-top: 20px;
    margin-bottom: 40px;  
}

.divisionItem__bajada--tit {

    font-family: 'NeueHaasUnicaProBold';
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bolder;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 17px;
    text-align: left;

}

.divisionItem__detail--tareas {

    padding: 5px 20px 6px 20px;
    border: 1px solid #dcd9d9;
    background-color: #dcd9d9;

    border-radius: 5rem ;
    color: #191919;
    font-family: 'NeueHaasUnicaProLight';
    font-size: .7rem;
    line-height: 1.4rem;
    margin-right: 10px;
    margin-bottom: 10px;
    white-space: nowrap;
    vertical-align: baseline;
    
    display: inline-block;

}

// =========== GALLERY ========== //

.divisionItem__gallery {

    display: block;
    border: 3px solid red;
    object-fit: contain;
}

.gallery__cont {

    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px;
    
}
.gallery__cont--column { flex: 20%; max-width: 20%; padding: 10px 10px 10px 0px; }
.gallery__cont--column25 { flex: 25%; max-width: 25%; padding: 10px 10px 10px 0px; }
.gallery__cont--column33 { flex: 33%; max-width: 33%; padding: 10px 10px 10px 0px; }
.gallery__cont--column50 { flex: 50%; max-width: 50%; padding: 10px 10px 10px 0px; }

.gallery__cont--column  img,
.gallery__cont--column25 img, 
.gallery__cont--column33 img,
.gallery__cont--column50 img {

    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    filter: grayscale(100);
    transition: all .3s ease;

}

.gallery__cont--column img:hover,
.gallery__cont--column25 img:hover, 
.gallery__cont--column33 img:hover,
.gallery__cont--column50 img:hover   {
    cursor: pointer;
    filter: grayscale(0);
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    transform: scale(1.05);
}


// =============== MODAL ================ // 

.modal__container{
    
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0%;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10050;

    visibility: hidden;
    opacity: 0; 
    transition: all 0.5s ease-in;

}

.modal__lapituquita {
    background-color: rgba( 255, 255, 255, .9);
    width: 80%;
    height: 80%;
    // max-height: 75%;
    border-radius: 5px;
    text-align: center;
    padding: 20px;

    position: relative;
    display: flex;
    justify-content: center;
}

.modal__lapituquita img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.modal__active {
    visibility: visible;
    opacity: 1;
}

.modal__close {
    position: absolute;
    top: -17px;
    right: -17px;
    background-color: white;
    width: 37px;
    height: 37px;
    border-radius: 55%;
    padding-top: 2px!important;
    transition: all .5 ease;
}
.modal__close:hover {
    background-color: black;
    color: #FFF;
    cursor: pointer;
}

.modal__button {

    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    cursor: pointer;

}

.modal__button:hover {
    color: red ;
}

.atras {
    left: 20px;
}
.siguiente {
    right: 20px
}

.fas {
    pointer-events: none;
}

.divisionItem__proyectosDestacados {
    justify-content: center;
    margin-bottom: 60px!important;
    width: 100vw;
}


// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
@media (max-width: 1024px) {
    .containerBlock { margin-top: 80px; }

    .gallery__cont--column,
    .gallery__cont--column25, 
    .gallery__cont--column33, 
    .gallery__cont--column50 {

        flex: 50%;
        max-width: 50%;
        padding: 0 4px;
    
    }

    .divisionItem__container--img {
        width: 100vw;
        height: 320px;
        display: block;
        object-fit: cover;
    }  
    
    .divisionItem__container--textos { width: auto; }

}

// // SIZE MD
@media (max-width: 768px) {

    .division__container--img {
        width: 100vw;
        min-height: 380px;
        object-fit: cover;
    }

    .divisionItem__detail--header {
        object-fit: fill;
    }

    .divisionItem__detail--block{
        padding: 60px 10px 10px 10px;
    }

    .divisionItem__container--textos { width: auto; }
    .divisionItem__container--textos-obras { top: 60px; left: 60px; }
    .divisionItem__container--title--telecomunicaciones { font-size: 2.8rem; }

    .gallery__cont--column,
    .gallery__cont--column25, 
    .gallery__cont--column33, 
    .gallery__cont--column50 {

        flex: 100%;
        max-width: 100%;
        padding: 0 4px;
    }

    .gallery__cont--columnRow {
        flex: 100%;
        max-width: 100%;
        padding: 0 4px;
    }

    
}
// // SIZE XS
@media (max-width : 576px) {

    .gallery__cont--column,
    .gallery__cont--column25, 
    .gallery__cont--column33, 
    .gallery__cont--column50 {
        flex: 100%;
        max-width: 100%;
        padding: 0 4px;
    }

    .gallery__cont--column  img,
    .gallery__cont--column25 img, 
    .gallery__cont--column33 img,
    .gallery__cont--column50 img {

        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
        filter: grayscale(100);
        transition: all 0.3s ease;
        height: 250px!important;
        padding-bottom: 20px;
        object-fit: co;
        
    }

    .containerBlock { margin-top: 80px; }

    .divisionItem__detail {
        // background-color: #FFF;
        padding:  60px 20px;
    }

    .divisionItem__detail--text {
        margin-bottom: 0px;  
    }

    .divisionItem__detail--block{
        padding: 30px 20px;
    }

    .division__container--img {
        width: 100vw;
        height: 820px;
        object-fit: cover;
    }

    .divisionItem__container--textos-obras { top: 60px; left: 60px; }
    .divisionItem__container--textos {
        position: absolute;
        top: 80px;
        left: 60px;
        width: auto;
    }
    
    .divisionItem__proyectosDestacados {
        padding: 20px;
    }
    
    .divisionItem__container--title {
        font-family: 'Chakra Petch';
        font-size: 3rem;
        line-height: 3rem;
        
        text-align: left;
        color: #f1f3f4;
        text-transform: uppercase;
    }
    
    .divisionItem__container--img--telecomunicaciones { width: 100vw; height: 240px; display: block; object-fit: cover; }
    .divisionItem__container--textos-telecomunicaciones { top: 60px; left: 30px!important; }
    .divisionItem__container--title--telecomunicaciones { font-size: 1.7rem; }

}