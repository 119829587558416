.divisionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px!important;
    padding-right: 10px!important;
}

.divisionContainerInternas {
    justify-content: center;
    margin-bottom: 60px!important;
}

.division__icon {
    width: 100%;
    
    img {
        width: 15px;
    }

}

.division__title{

    color: #191919;
    display: none;
    font-family: 'NeueHaasUnicaProBold';
    font-size: 1.8rem;
    line-height: 1.8rem;
    font-weight: bolder;
    margin-top: 0px;
    padding-bottom: 20px;
    text-align: center;

}

// SIZE XL
@media (max-width: 1200px) {
    .divisionContainer {
        justify-content: center;
    }
}
// SIZE L
@media (max-width: 1024px) {
   
}
// SIZE MD
@media (max-width: 768px) { 
    .divisionContainer {
        flex-wrap: wrap;
    }
    .division__title{
        display: block;
    }
    
}
// SIZE XS
@media (max-width : 576) {

    .division__title{
        display: block;
    }
    
    
}