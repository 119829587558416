@use '../../../Styles/main.scss';

@keyframes loading__anim {
    from {width: 1vw;}
    to{width: 100vw}
}

.loading {
    margin-top: 100px;
    height: 4px;
    background-color: #2e353f;
    animation-name: loading__anim;
    animation-duration: 1.5s;
    margin-bottom: 800px;
}

.display-block {
    padding-top: 100px!important;
}
  
@media (max-width: 768px) {
    .loading {
        margin-top: 80px;
    }
    .display-block {
        padding-top: 80px!important;
    }
}