

.box {
    border: 1px solid green;
}

.containerBlock {
    margin-top: 100px;
}

.quienesSomos__container {
    position: relative;
    text-align: left;
    color: #f1f3f4;
}

.quienesSomos__header--img {
    width: 100vw;
    height: 700px;
    object-fit: cover;
}

.quienesSomos__header--title {
    margin-top: 20px;
    line-height: 3.2rem;
}

.quienesSomos__detail--header {
    object-fit: cover;
    height: 100%;
}

.quienesSomos__container--textos {
    position: absolute;
    top: 120px;
    left: 120px;
    width: 50%;
}

.quienesSomos__container--header {
    font-family: 'NeueHaasUnicaPro';
    color: #f1f3f4;
    font-size: 1rem;
    border-bottom: 1px solid white;
    width:fit-content;
    padding-bottom: 14px;
}

.quienesSomos__container--title {
    font-family: 'Chakra Petch';
    font-size: 3.5rem;
    line-height: 3.2rem;
    text-align: left;
    color: #f1f3f4;
    text-transform: uppercase;
    margin-top: 20px;
}

.quienesSomos__container--text {
    font-family: "NeueHaasUnicaProLight";
    font-size: 1rem;
    line-height: 1.7rem;
    color: #f1f3f4;
    font-weight: lighter;
    margin-top: 20px;
}

.quienesSomos__container--images {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px!important;
}

.quienesSomos__container--images img {
    padding: 14px 7px;
    height: auto;
    width: 16%;
    filter: grayscale(100);
    transition: all 0.3s ease;
}


.quienesSomos__container--images img:hover {
    cursor: pointer;
    filter: grayscale(0);
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    transform: scale(1.09);
}

.containerBlock__25 {
    background-image: url('../../../public/images/quienes-somos/cuenca-golfo-san-jorge.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 900px;
    object-fit: cover;
    background-color: #FFF;
    padding: 100px;
    min-height: 750px;
    padding-bottom: 120px!important ;;
    
}

.containerBlock__25--bloques {
    display: flex;
}

.containerBlock__gallery {

    display: flex;
    flex-wrap: nowrap;
    padding-top: 120px;

}

.containerBlock__column {
    flex: 30%;
    max-width: 30%;
    padding: 0 4px;
}

.containerBlock__column img {
   vertical-align: middle;
   margin-top: 10px;
   width: 100%;
   filter: grayscale(100);
   transition: all 0.3s ease;
}

.containerBlock__column img:hover {
    cursor: pointer;
    filter: grayscale(0);
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    transform: scale(1.09);
 }



.containerBlock__25--text {
    padding: 100px 20px;
}

.containerBlock__2022 {
    background-image: url('../../../public/images/quienes-somos/background-2022.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 900px;
    object-fit: cover;    
}

.quienesSomos__container--titleBlack {
    font-family: 'Chakra Petch';
    font-size: 3.5rem;
    line-height: 3.2rem;
    text-align: left;
    color: #121212;
    text-transform: uppercase;
    margin-top: 20px;
}

.quienesSomos__container--textBlack {
    font-family: "NeueHaasUnicaProLight";
    font-size: 1rem;
    line-height: 1.7rem;
    color: #121212;
    font-weight: lighter;
    margin-top: 20px;
}

.quienesSomos_hr {
    border-bottom: 1px solid rgba(0,0,0,0.5);
    /* color: grey; */
    /* background-color: grey; */
    display: inline-block;
    width: 100%;
    padding-bottom: 0.75rem;
    padding-top: 0.65rem;
}



// // SIZE XXL
@media (max-width: 1400px) {}
// // SIZE XL
@media (max-width: 1200px) {}
// // SIZE L
@media (max-width: 1024px) {
    .quienesSomos__container--images img {
        padding: 14px 7px;
        height: auto;
        width: 33%;
    }

    .quienesSomos__container--textos {
        top: 90px;
        left: 60px;
        width: 80%;
        padding-right: 60px;
    }

}
// // SIZE MD
@media (max-width: 768px) {}
// // SIZE XS
@media (max-width: 576px) { 
    
    
    .quienesSomos__header--img {
        height: 750px;
        object-fit: cover;
    }

    .containerBlock__25 {
        padding-bottom: 50px!important;
        background-image: none;
    }
    .containerBlock__25--bloques {
        flex-wrap: wrap-reverse;
    }
    .justify-content-center {
        justify-content: center!important;
    }

    .containerBlock__25--text {
        padding: 80px 20px 40px 20px
    }
    .quienesSomos__container--textos {
        top: 90px;
        left: 30px;
        width: auto;
        padding-right: 30px;
    }

    .containerBlock__gallery {
        padding: 40px 0px 60px 0px;
        justify-content: center;
    }
    
}

