.bannerAnivesaryContainer {
    position: relative;
}

.bannerAnivesary__block {
    
    position: relative;
    display: flex;
    align-content: flex-end;

    width: 103%;
    min-height: 550px;
    padding-bottom: 60px;

    background: #FFF fixed no-repeat 50% 50%;
    background-image: url("../../../../public/images/home/banner-25.jpg");
    background-size: cover;

}

.text__25 {
    position: absolute;

    font-family: 'Chakra Petch', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15rem;
    line-height: 15rem;
    color: rgba(255,255,255,.05);
    letter-spacing: -1rem;
    bottom: 20%;

    animation-name: entraTexto25;
    animation-duration: 30s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;

}

.bannerAnivesary__text {
    // position: absolute;
    font-family: 'NeueHaasUnicaPro';
    color: #bdbdbd;
    // margin: 0 auto;
    bottom: 20px;
}

.bannerAnivesary__text--white {
    font-family: 'NeueHaasUnicaProBold';
    font-size: 1.8rem;
    line-height: 1.8rem;
    display: block;
    color: #FFF;
    text-transform: uppercase;

}

.bannerAnivesary__text--grey {
    font-family: 'NeueHaasUnicaPro';
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding-top: 4px;
    display: block;
    color: #bdbdbd;
}

.bannerAniversary__col {
    width: 3%!important;
}

.bannerAnivesary__text--line {
    height: 70px;
}

@keyframes entraTexto25 {
    from{ opacity: 1; transform: translateX(300px); }
    to{ opacity: 1; transform: translateX(1000px);}
}


// SIZE XXL
@media (max-width: 1400px) {}

// SIZE XL
@media (max-width: 1200px) {}

// SIZE L
@media (max-width: 1024px) {

    .bannerAnivesary__text {
        font-size: 1.3rem;
        line-height: 1.3rem;
    }
    .bannerAnivesary__text--white, .bannerAnivesary__text--grey {
        font-size: 1.2rem;
        
    }
    .bannerAnivesary__text--border { width: 1%!important}
}

// SIZE MD
@media (max-width: 768px) { .bannerAnivesary__text--border { display: none;} }

// SIZE XS
@media (max-width : 576px) {
   
    .text__25 {
        font-family: 'Chakra Petch', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 10rem;
        line-height: 10rem;
        bottom: 170px;
        left: 20px;
    
        animation-name: entraTexto25;
        animation-duration: 30s;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
    }

    @keyframes entraTexto25 {
        from{ opacity: 1; transform: translateX(0px); }
        to{ opacity: 1; transform: translateX(500px);}
    }

    .bannerAnivesary__block {
        background: #FFF fixed no-repeat 50% 50%;
        background-image: url("../../../../public/images/home/banner-25-mobile.jpg");
        background-size: cover;
        flex-direction: column;
        padding: 50px 20px 50px 30px;
        justify-content: flex-end;


    }
    .bannerAniversary__col {
        width: 100%!important;
    }

    .bannerAnivesary__text{
        padding-top: 10px;
    }

    .bannerAnivesaryContainer {
        min-height: 290px;
        background-color: #000;
    }

    .bannerAnivesary__text--line {
        height: 2px;
    }

    .bannerAnivesary__text--white, .bannerAnivesary__text--grey {
        display: inline;
        padding-left: 1rem;
    }
        
}
