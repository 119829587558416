// ++++++++ HOME OBRAS +++++++++ //
.obras__realizadas {
    object-fit: cover;
    height: 150px;
    width: 100%;
  }
  
  
  .obras__container {
    background-color: #f1f3f4;
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url('../../../../public/images/home/back-obras.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px ;
}
.obras__titles {

    font-family: 'NeueHaasUnicaProBold';
    color: #191919;
    font-weight: bolder;
    font-size: 1.6rem;

}

.obras__titles--box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.obra__item {
    
    margin-right: 10px;
    margin-left: 10px;
    padding: 8px;
    // margin: 10px;
    background-color: #FFF;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 340px;

}

.obra__item img {
    max-width: 100%;
    height: 100%;
}

.obra__item .background-hover {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 570px;
    left: 0px;
    background-color: rgba(0,0,0,.25);
    transition: all 300ms;
}

.obra__item--text {
    font-size: 1.1rem;
    text-align: center;
    color: #FFF;
    margin-bottom: 10px;
    font-weight: 600;
    padding-left: .5rem;
    padding-right: .5rem;
}

.obra__item:hover   {

    background-color: #2e353f;

    .background-hover {
        padding-top: 60%;
        height: 100%; 
        top: 0px
    }
}

.button__item {
    font-family: 'NeueHaasUnicaPro';
    color: #191919;
    font-weight: lighter;
    font-size: .8rem;
    display: flex;
    flex-direction: row;
    align-items: center;

}
.button__item:hover {
    opacity: .6; 
}


// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
@media (max-width: 1024px) {
    .obras__container{
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .obra__item {
        min-height: 270px;
    }
    .obra__item .background-hover {
        height: 100%;
        top: 380px;
    }

    .obra__item:hover   {
        background-color: #2e353f;
        .background-hover {
            padding-top: 20%;
            height: 100%; 
            top: 0px
        }
    }

    .obra__item--text {
        font-size: 1.4rem;
        margin-bottom: 10px;
        font-weight: 600;
        width: 70%;
        margin: 0 auto;
        margin-bottom: 20px;
    }

}
// // SIZE MD
// @media (max-width: 768px) {}
// // SIZE XS
@media (max-width : 576px) {

    .obras__container{
        padding: 80px 40px 80px 40px!important;
    }

    .obra__item {
        margin: 10px 30px 10px 30px;
        min-height: 240px;
    }
    .obra__item .background-hover {
        height: 100%;
        padding-top: 22%;
        top: 0px;
    }

    .obra__item:hover   {
        background-color: #2e353f;
        .background-hover {
            padding-top: 20%;
            height: 100%; 
            top: 0px
        }
    }
}