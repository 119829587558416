.proyectoContainer{
    margin-bottom: 2rem!important;
}

.proyectoContainer__thumb {
    padding: 0px 0px 0px 0px;
    background-color: #fff;  
    max-width: 85%;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.05);
    // margin-bottom: 40px;
    height: 100%;
}

.obras__container { background-color: #f1f3f4; }

.obras__titles {
    font-family: 'NeueHaasUnicaProMedium';
    color: #191919;
    font-weight: bolder;
    font-size: 1.8rem;
}

.proyecto__button { top: 40%; left: 40% }

.proyecto__item  {
    
    padding: 0px;
    background-color: #FFF;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 10px 10px #2e353f;
    
    .proyecto__item--marco {
        position: absolute;
        z-index: 50;
        width: 100%;
        height: 100%;
        border: 10px solid rgba(46,53,63,0.0);
        transition: all .5s ease-in-out;

    }

    img {
        z-index: 45;
        filter: grayscale(100%);
        transition: all .5s ease-in-out;

    }
}

.proyecto__item:hover  {

    .proyecto__item--marco {
        border: 10px solid #2e353f;
        transition: all .5s ease-in-out;
    }

    img { 
        filter: grayscale(0%);
        transition: all .5s ease-in-out;
    }
    
   
    
}

.proyecto__item--front { position: relative; }

.proyecto__item--buttom {
    position: relative;
    margin-top: 21%;
    text-align: center;
}

.proyecto__item--text {
    font-size: 1rem;
    text-align: center;
    color: #FFF;
    margin-bottom: 10px;
}

.proyecto__item:hover   {
    background-color: #2e353f;
    .background-hover {
        // padding-top: 35%;
        // height: 450px; 
        // top: 0px;
        border: 10px solid red;
        filter: grayscale(0%);
    }
}

.proyecto__tit {
    font-family: 'NeueHaasUnicaPro';
    font-size: 1.2em;
    line-height: 1.6rem;
    font-weight: bolder;
    padding: 40px 60px 10px 60px;
    text-align: center;
    min-height: 101px; 
}


.proyecto__division {
    font-family: 'NeueHaasUnicaProLight';
    font-size: .5em;
    text-transform: uppercase;
    font-weight: bolder;
    text-align: center;
    margin-bottom: px;
    display: block;
    line-height: .7rem;
}


// // SIZE XXL
// @media (max-width: 1400px) {}
// // SIZE XL
// @media (max-width: 1200px) {}
// // SIZE L
@media (max-width: 1024px) {
    .poyerct__item {
        padding: 10px;
    }
    .obras__container{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .proyectoContainer {
        padding: 0px;
    }
}
// // SIZE MD
@media (max-width: 768px) {
    .proyecto___tit{
        padding: 20px 40px;
    }
    .proyectoContainer {
        max-width: 95%;
    }
}
// // SIZE XS
// @media (max-width : 576) {}